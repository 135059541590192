import React, { useState, forwardRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Portal from '@material-ui/core/Portal';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import AlertBar from '../AlertBar';
import { firebaseErrorMessage } from '../../utils/functions';

const useStyles = makeStyles(theme => ({
    circularProgress: {
        margin: '0.1rem 2.6rem'
    },
    title: {
        color: theme.palette.secondary.main,
        paddingBottom: 0,
        textAlign: 'center'
    },
    forgottenPassword: {
        color: '#f48fb1',
        fontWeight: '400',
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    register: {
        textAlign: 'center',
        marginTop: '1rem',
        '& a': {
            color: theme.palette.secondary.main
        }
    },
    button: {
        margin: '0 1.1rem 1rem'
    }
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const LoginDialog = () => {
    const [error, setError] = useState('');
    const [forgottenPassword, setForgottenPassword] = useState(false);
    const [message, setMessage] = useState('');
    const { login, loading, setLoading, resetPassword, openLoginDialog, setOpenLoginDialog } = useAuth();
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpenLoginDialog(true);
    };

    const handleClose = () => {
        setOpenLoginDialog(false);
        setForgottenPassword(false);
    };

    const changePassword = () => {
        setForgottenPassword(true)
    };

    const initialValues = forgottenPassword ? { email: '' } : { email: '', password: '' }

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setLoading(true);
        setMessage('');
        try {
            setError('');
            await login(values.email, values.password);
            resetForm();
            setSubmitting(false);
        } catch (err) {
            console.log(err);
            setError(firebaseErrorMessage(err));
            setLoading(false);
        }        
    };

    const handleForgottenPassword = async (values, { setSubmitting, resetForm }) => {
        setLoading(true);
        setMessage('');
        try {
            setError('');
            await resetPassword(values.email);
            resetForm();
            setSubmitting(false);
            handleClose();
            setMessage('Un email vous a été envoyé pour réinitialiser votre mot de passe.');
        } catch (err) {
            console.log(err);
            setError(firebaseErrorMessage(err));
        }        
        setLoading(false);
    };

    return (
        <div>
            <Portal>
                <AlertBar error={error} message={message} />
            </Portal>
            <Button color='secondary' variant='outlined' onClick={handleClickOpen}>
                {loading ? <CircularProgress className={classes.circularProgress} color="secondary" size={20} /> : 'SE CONNECTER'}
            </Button>
            <Dialog
                open={openLoginDialog}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-labelledby="login-dialog"
            >
                <DialogTitle id="login-dialog" className={classes.title}>
                    {forgottenPassword ? 'Entrez votre adresse Email' : 'Entrez vos identifiants de connexion'}
                </DialogTitle>
                <Formik 
                    initialValues={initialValues} 
                    onSubmit={forgottenPassword ? handleForgottenPassword : handleSubmit}
                >
                    {() => (
                        <Form>
                            <DialogContent style={{paddingTop: forgottenPassword && '0.5rem'}}>
                                <Field component={TextField} label='Email' name='email' type='email' variant='outlined' fullWidth color='secondary' margin='normal' required autoFocus />
                                {!forgottenPassword && <Field component={TextField} label='Mot de passe' name='password' type='password' variant='outlined' fullWidth color='secondary' margin='normal' required />}
                                {!forgottenPassword && <Typography className={classes.forgottenPassword} variant='subtitle2' onClick={changePassword}>Mot de passe oublié?</Typography>}
                                <Typography className={classes.register} variant='body1' color='secondary'>
                                    Pas encore membre ? <Link to='/signup' onClick={handleClose}>Rejoignez-nous gratuitement !</Link>
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button className={classes.button} type='submit' color='secondary' variant='outlined' disabled={loading} fullWidth>
                                    {loading ? <CircularProgress color="secondary" size={24} /> : (forgottenPassword ? 'CONFIRMER' : 'SE CONNECTER')}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}                    
                </Formik>
            </Dialog>
        </div>
    );
};

export default LoginDialog;