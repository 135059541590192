import React from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import StarIcon from '@material-ui/icons/Star';
import virtuals from '../../img/virtuals.jpg';
import pandemic from '../../img/pandemic.jpg';
import onlineDating from '../../img/online-dating.jpg';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#eee',
        padding: '3.8rem 0',
        fontWeight: 500
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '0 0.5rem'
        }
    },
    title: {
        color: theme.palette.secondary.main,
        textAlign: 'center',
        fontWeight: 500,
        fontSize: '1.4rem',
        marginBottom: '0.3rem'
    },
    subtitle: {
        textAlign: 'center',
        fontSize: '1.2rem',
        fontWeight: 500,
        color: '#444',
        marginBottom: '2.5rem'
    },
    card: {
        borderRadius: '10px',
        maxWidth: 500
    },
    cardTitle: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        fontSize: '1rem'
    },
    cardSubheader: {
        fontSize: '0.8rem'
    },
    media: {
        height: 250,
    },
    btn: {
        marginTop: '2.5rem'
    }
}));

const BlogIntro = () => {
    const classes = useStyles();

    return (
        <section className={classes.root}>
            <Container maxWidth='lg' className={classes.container}>
                <Typography variant='h6' className={classes.title}>Toute l'équipe d'Adhamo est à vos côtés pour vous aider à faire de belles rencontres.</Typography>
                <Typography variant='h6' className={classes.subtitle}>Nous vous avons préparé ces quelques idées pour vous rassurer et vous soutenir.</Typography>
                <Grid container justify='center' alignItems='center' spacing={3}>
                    <Grid item xs={12} md={4} container justify='center' alignItems='center'>
                        <Card className={classes.card}>
                            <CardActionArea component={Link} to='/rencontres-pandemie'>
                                <CardHeader classes={{title: classes.cardTitle, subheader: classes.cardSubheader}} title='Faire des rencontres en période de pandémie' subheader='par Luc' />
                                <CardMedia className={classes.media} image={pandemic} title='rencontres et pandémie' />
                            </CardActionArea>
                            <CardContent>
                                <Typography variant='body1' gutterBottom>
                                    Soyons honnêtes, faire des rencontres peut être difficile même dans des circonstances normales, mais pendant une pandémie, cela peut être très délicat.
                                </Typography>
                                <Typography variant='body1'>
                                    Que vous recherchiez une relation sérieuse ou sans prise de tête, voici quelques conseils pour faire des rencontres en période de pandémie.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} container justify='center' alignItems='center'>
                        <Card className={classes.card}>
                            <CardActionArea component={Link} to='/idees-rdv-virtuels'>
                                <CardHeader classes={{title: classes.cardTitle, subheader: classes.cardSubheader}} title='18 idées de rdv virtuels' subheader='par Florence' />
                                <CardMedia className={classes.media} image={virtuals} title='rencontres et pandémie' />
                            </CardActionArea>
                            <CardContent>
                                <Typography variant='body1' gutterBottom>
                                    Maintenant que la plupart du monde est bien dans l’isolement social, les rencontres semblent devenues une chose du passé.
                                </Typography>
                                <Typography variant='body1'>
                                    À l’ère du coronavirus, les gens affluent vers les plateformes de rencontres en ligne pour trouver la personne de leurs rêves, organisant même des premiers rendez-vous complètement virtuels.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} container justify='center' alignItems='center'>
                        <Card className={classes.card}>
                            <CardActionArea component={Link} to='/aborder-sur-internet'>
                                <CardHeader classes={{title: classes.cardTitle, subheader: classes.cardSubheader}} title='Comment aborder une personne sur internet?' subheader='par Florence' />
                                <CardMedia className={classes.media} image={onlineDating} title='rencontres et pandémie' />
                            </CardActionArea>
                            <CardContent>
                                <Typography variant='body1' gutterBottom>
                                    Vous essayer donc de rencontre quelqu'un.
                                </Typography>
                                <Typography variant='body1'>
                                    Les e-mails, les sites de rencontres et les services de messagerie instantanée peuvent faciliter la communication avec nos amis et notre famille, 
                                    mais il peut être difficile de connaître une nouvelle personne lorsque vous ne parlez pas en face à face.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Button className={classes.btn} variant='outlined' color='secondary' component={Link} to='/idees' startIcon={<StarIcon />} size='large'>Découvrez toutes nos idées pour vous !</Button>
            </Container>
        </section>
    );
};

export default BlogIntro;