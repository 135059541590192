import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import couple from '../../img/couple.jpg';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { useAuth } from '../../context/AuthContext';

const useStyles = makeStyles(theme => ({
    backgroundImage: {
        position: 'relative',
        backgroundImage: `url(${couple})`,
        backgroundPosition: '50% 40%',
        backgroundRepeat: 'no-repeat',
        maxWidth: '1920px',
        height: '75vh',
        margin: '0 auto'
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.2)'
    },
    cta: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        color: '#fff',
        marginBottom: '1.5rem',
        textShadow: '1px 1px 2px black',
        textAlign: 'center'
    },
    subtitle: {
        color: '#fff',
        fontSize: '1.4rem',
        marginBottom: '2.5rem',
        textDecoration: 'underline',
        textShadow: '1px 1px 2px black',
        textAlign: 'center'
    },
    subtitle2: {
        color: theme.palette.secondary.main,
        fontSize: '1.1rem',
        textShadow: '1px 1px 2px rgba(0,0,0,0.2)',
        fontWeight: '500',
        textAlign: 'center',
        marginBottom: '-1.5rem'
    },
    welcome: {
        backgroundColor: 'rgba(255,255,255,0.7)',
        borderRadius: '12px',
        padding: '0.5rem 1rem',
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
    },
    fab: {
        padding: '0 2rem'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    }
}));

const Header = () => {
    const classes = useStyles();
    const { currentUser } = useAuth();

    return (
        <header className={classes.backgroundImage}>
            <div className={classes.overlay}>
                <Container maxWidth='lg' style={{height: '100%'}}>
                    <Grid container style={{height: '100%'}}>
                        <Grid className={classes.cta} item xs={12} md={6}>
                            <Typography className={classes.title} variant='h3'>Découvrez une nouvelle manière de faire des dates</Typography>
                            <Typography className={classes.subtitle} variant='subtitle1'>Faites d'authentiques rencontres avec Adhamo !</Typography>
                            {currentUser ? 
                                <Typography className={classes.welcome} variant='h5' color='secondary'>Bienvenue sur Adhamo</Typography> :
                                <Fab className={classes.fab} component={Link} variant="extended" color='secondary' to='/signup' aria-label="s'inscrire">
                                    Rejoindre gratuitement
                                </Fab>
                            }
                            <Typography className={classes.subtitle2} variant='subtitle1'>Accès complet gratuit pour tous !</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </header>
    );
};

export default Header;