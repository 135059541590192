import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    message: {
        padding: '10px 0'
    },
    action: {
        paddingLeft: 0
    }
});

const CookiesBar = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };

    const message = 
        <Typography variant='body2'>
            Ce site utilise des cookies pour vous offrir un service de qualité et analyser le traffic. En continuant à utiliser ce site, vous acceptez notre <Link to='/cookies' style={{color: 'white'}}>politique relative aux cookies</Link>.
        </Typography>;

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={open}
                onClose={handleClose}
                TransitionComponent={Slide}
                autoHideDuration={10000}
            >
                <SnackbarContent 
                    classes={{
                        message: classes.message,
                        action: classes.action
                    }}
                    message={message}
                    action={
                        <Button onClick={handleClose} color='secondary' aria-label="fermer">OK</Button>
                    } 
                />
            </Snackbar>
        </div>
    );
};

export default CookiesBar;