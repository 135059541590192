import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Portal from '@material-ui/core/Portal';
import { auth } from '../utils/firebase';
import firebase from 'firebase/app';
import axios from '../utils/axios.js';
import AlertBar from '../components/AlertBar';
import he from 'he';
import manDefault from '../img/manDefault.png';
import womanDefault from '../img/womanDefault.png';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [signupLoading, setSignupLoading] = useState(false);
    const [error, setError] = useState('');
    const [openLoginDialog, setOpenLoginDialog] = useState(false);
    const [ready, setReady] = useState(true);
    const [totalUnread, setTotalUnread] = useState(0); 
    const [newLikes, setNewLikes] = useState(0);
    const history = useHistory();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async authUser => {
            setError('');
            if (authUser && ready) {
                try {
                    if (!authUser.emailVerified) {
                        history.push('/validation-email');
                    } else {
                        const idToken = await authUser.getIdToken();
                        const response = await axios.post('/api/users/login', { email: authUser.email }, { headers: { Authorization: idToken }});
                        const photoUrl = response.data.photoUrl ? he.decode(response.data.photoUrl) : (response.data.genre === 'femme' ? womanDefault : manDefault);
                        setCurrentUser({...response.data, photoUrl, ville: he.decode(response.data.ville), prenom: he.decode(response.data.prenom), veutDesEnfants: response.data.veutDesEnfants && he.decode(response.data.veutDesEnfants), activite: response.data.activite && he.decode(response.data.activite)});
                        if (authUser.metadata.creationTime === authUser.metadata.lastSignInTime) {
                            history.push('/profile-edit');
                        } else {
                            history.push('/profile');
                        }
                    }
                } catch (err) {
                    console.log(err);
                    if (err.response) {
                        setError(err.response.data.message);
                    } else {
                        setError('Connexion au serveur impossible, merci de réessayer plus tard.');
                    }
                }
                setSignupLoading(false);
            } else {
                setCurrentUser(null);
            }
            setLoading(false);
            setOpenLoginDialog(false);
        });
        return () => {
            unsubscribe();
        }
    }, [history, ready]);

    const signup = (email, password) => {
        return auth.createUserWithEmailAndPassword(email, password);
    };

    const login = (email, password) => {
        return auth.signInWithEmailAndPassword(email, password);
    };

    const logout = () => {
        return auth.signOut();
    };

    const resetPassword = email => {
        return auth.sendPasswordResetEmail(email);
    };

    const updateEmail = email => {
        return auth.currentUser.updateEmail(email);
    };

    const updatePassword = password => {
        return auth.currentUser.updatePassword(password);
    };

    const reAuthenticate = (email, password) => {
        const credential = firebase.auth.EmailAuthProvider.credential(email, password);
        return auth.currentUser.reauthenticateWithCredential(credential);
    };

    return (
        <AuthContext.Provider value={{ currentUser, setCurrentUser, loading, setLoading, signupLoading, setSignupLoading, setReady, signup, login, logout, resetPassword, updateEmail, updatePassword, reAuthenticate, totalUnread, setTotalUnread, newLikes, setNewLikes, openLoginDialog, setOpenLoginDialog }}>
            <Portal>
                <AlertBar error={error} />
            </Portal>
            {children}
        </AuthContext.Provider>
    );
};