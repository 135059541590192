import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import LoginDialog from './LoginDialog';
import { useAuth } from '../../context/AuthContext';
import AvatarMenu from './AvatarMenu';
import heartsmall from '../../img/heartsmall.png'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'rgba(255, 255, 255, 0.95)'
    },
    title: {
        fontFamily: 'Merriweather, serif',
        textDecoration: 'none',
        transition: 'text-shadow 0.2s ease-in-out',
        '&:hover': {
            textShadow: `1px -1px ${theme.palette.secondary.main}, 2px -2px ${theme.palette.secondary.main}`
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.6rem'
        }
    },
    container: {
        [theme.breakpoints.down('xs')]: {
            padding: '0 0.2rem'
        }
    },
    bar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none'
    }
}));

const Navbar = () => {
    const { currentUser } = useAuth();
    const classes = useStyles();
    return (
        <AppBar classes={{root: classes.root}} position='sticky'>
            <Container maxWidth='lg' className={classes.container}>
                <Toolbar className={classes.bar}>
                    <Link className={classes.logo} to='/'>
                        <img src={heartsmall} alt='heart' height={40} />
                        <Typography variant="h4" className={classes.title} color='secondary'>
                            Adhamo
                        </Typography>
                    </Link>
                    {currentUser ? <AvatarMenu /> : <LoginDialog />}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Navbar;