import React, { useState, createContext, useContext, useEffect } from 'react';
import Portal from '@material-ui/core/Portal';
import useOnlineProfiles from '../hooks/useOnlineProfiles';
import AlertBar from '../components/AlertBar';

const ProfilesContext = createContext();

export const useProfiles = () => useContext(ProfilesContext);

export const ProfilesProvider = ({ children }) => {
    const [profile, setProfile] = useState(null);
    const [initialProfiles, setInitialProfiles] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const {onlineProfiles, getProfiles, errorOnline} = useOnlineProfiles();

    useEffect(() => {
        getProfiles();
    }, [getProfiles, initialProfiles]);

    useEffect(() => {
        if (onlineProfiles.length) {
            const updatedProfiles = initialProfiles.map(profile => {
                if (onlineProfiles.includes(profile.id)) {
                    return {...profile, online: true};
                } else {
                    return profile;
                }
            });
            updatedProfiles.sort((a, b) => {
                if (a.emailVerified && !b.emailVerified) return 1;
                if (b.emailVerified && !a.emailVerified) return -1;
                if (a.online && !b.online) return 1;
                if (b.online && !a.online) return -1;
                return 0;
            });
            setProfiles(updatedProfiles);
            setLoading(false);
        }
    }, [onlineProfiles, initialProfiles]);

    return (
        <ProfilesContext.Provider value={{profile, setProfile, setInitialProfiles, profiles, setProfiles, onlineProfiles, loading, setLoading}}>
            <Portal>
                <AlertBar error={errorOnline} />
            </Portal>
            {children}
        </ProfilesContext.Provider>
    );
};