import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import Portal from '@material-ui/core/Portal';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import HelpIcon from '@material-ui/icons/Help';
import ForumIcon from '@material-ui/icons/Forum';
import PeopleIcon from '@material-ui/icons/People';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FlareIcon from '@material-ui/icons/Flare';
import { NavLink, useHistory } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';
import AlertBar from '../AlertBar';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& a': {
            textDecoration: 'none'
        }
    },
    btn: {
        textTransform: 'none',
        fontWeight: 'normal',
        padding: '0.9rem 1.5rem',
        borderRadius: '9px',
        '&:hover': {
            backgroundColor: 'rgba(248, 187, 208, 0.3)'
        }
    },
    avatar: {
        marginLeft: '0.2rem',
        width: theme.spacing(6),
        height: theme.spacing(6),
        border: `1px solid ${theme.palette.secondary.main}`,
        cursor: 'pointer',
        transition: 'opacity 200ms',
        '&:hover': {
            opacity: 0.85
        }
    },
    menu: {
        color: theme.palette.secondary.main
    },
    divider: {
        margin: '0 auto',
        width: '90%',
        backgroundColor: theme.palette.secondary.light
    }
}));

const activeStyle = {
    borderRadius: '9px 9px 0px 0px',
    borderBottom: '0.25rem solid #e91e63',
    backgroundColor: 'transparent',
    paddingBottom: '0.95rem',
    paddingTop: '0.9rem',
    marginBottom: '-0.3rem'
};

const AvatarMenu = () => {
    const { currentUser, logout, totalUnread, newLikes } = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const [error, setError] = useState('');
    const history = useHistory();
    const matches = useMediaQuery('(min-width: 760px)');
    const classes = useStyles();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogOut = async () => {
        try {
            await logout();
        } catch (err) {
            console.log(err);
            setError(err.message);
        }
    };

    const onClickProfile = () => {
        handleClose();
        history.push('/profile');
    };
    const onClickMeslikes = () => {
        handleClose();
        history.push('/meslikes');
    };
    const onClickMessages = () => {
        handleClose();
        history.push('/messages');
    };
    const onClickDonate = () => {
        handleClose();
        history.push('/don');
    };
    const onClickAide = () => {
        handleClose();
        history.push('/aide');
    };
    const onClickIdees = () => {
        handleClose();
        history.push('/idees');
    };

    return (
        <div className={classes.container}>
            <Portal>
                <AlertBar error={error} />
            </Portal>
            {matches ?
                <div>
                    <Button className={classes.btn} color='secondary' component={NavLink} to='/rencontres' activeStyle={activeStyle} startIcon={<PeopleIcon style={{fontSize: 25}} />}>Rencontres</Button>
                    <Button className={classes.btn} color='secondary' component={NavLink} to='/likes' activeStyle={activeStyle} startIcon={<Badge badgeContent={newLikes} max={99} color='error'><FavoriteIcon style={{fontSize: 25}} /></Badge>}>Likes</Button>                    
                    <Button className={classes.btn} color='secondary' component={NavLink} to='/messages' activeStyle={activeStyle} startIcon={<Badge badgeContent={totalUnread} max={99} color='error'><ForumIcon style={{fontSize: 25}} /></Badge>}>Messages</Button>
                </div> :
                <div>
                    <IconButton color='secondary' component={NavLink} to='/rencontres' activeStyle={{backgroundColor: 'rgba(248, 187, 208, 0.3)'}}><PeopleIcon style={{fontSize: 25}} /></IconButton>
                    <IconButton color='secondary' component={NavLink} to='/likes' activeStyle={{backgroundColor: 'rgba(248, 187, 208, 0.3)'}}><Badge badgeContent={newLikes} max={99} color='error'><FavoriteIcon style={{fontSize: 25}} /></Badge></IconButton>
                    <IconButton color='secondary' component={NavLink} to='/messages' activeStyle={{backgroundColor: 'rgba(248, 187, 208, 0.3)'}}><Badge badgeContent={totalUnread} max={99} color='error'><ForumIcon style={{fontSize: 25}} /></Badge></IconButton>
                </div>
            }
            <Avatar className={classes.avatar} onClick={handleMenu} src={currentUser.photoUrl} aria-controls="menu-principal" aria-haspopup="true" />
            <Menu 
                anchorEl={anchorEl} classes={{paper: classes.menu}}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                getContentAnchorEl={null} keepMounted id="menu-principal"
                open={Boolean(anchorEl)} onClose={handleClose}
            >
                <MenuItem onClick={onClickProfile}>
                    <ListItemIcon>
                        <AccountCircleIcon color='secondary' />
                    </ListItemIcon>
                    <ListItemText primary='Mon profil' />
                </MenuItem>
                <MenuItem onClick={onClickMeslikes}>
                    <ListItemIcon>
                        <FavoriteIcon color='secondary' />
                    </ListItemIcon>
                    <ListItemText primary='Mes Likes' />
                </MenuItem>
                <MenuItem onClick={onClickMessages}>
                    <ListItemIcon>
                        <Badge badgeContent={totalUnread} color='error'>
                            <ForumIcon color='secondary' />
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary='Messages' />
                </MenuItem>
                <Divider className={classes.divider} />
                <MenuItem onClick={onClickDonate}>
                    <ListItemIcon>
                        <AccessibilityNewIcon color='secondary' />
                    </ListItemIcon>
                    <ListItemText primary='Faire un don' />
                </MenuItem>
                <MenuItem onClick={onClickIdees}>
                    <ListItemIcon>
                        <FlareIcon color='secondary' />
                    </ListItemIcon>
                    <ListItemText primary='Idées et Conseils' />
                </MenuItem>
                <MenuItem onClick={onClickAide}>
                    <ListItemIcon>
                        <HelpIcon color='secondary' />
                    </ListItemIcon>
                    <ListItemText primary='Aide' />
                </MenuItem>
                <MenuItem onClick={handleLogOut}>
                    <ListItemIcon>
                        <LockIcon color='secondary' />
                    </ListItemIcon>
                    <ListItemText primary='Se déconnecter' />
                </MenuItem>
            </Menu>
        </div>
    );
};

export default AvatarMenu;