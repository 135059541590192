import { createMuiTheme } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import pink from '@material-ui/core/colors/pink';
import grey from '@material-ui/core/colors/grey';

// const primary = teal[50];
const primary = grey[100];
const primaryDark = teal[500];
// const secondary = pink[300];
const secondary = pink[500];
const secondaryLight = '#fde2e2';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: primary,
            dark: primaryDark
        },
        secondary: {
            light: secondaryLight,
            main: secondary
        }
    }
});

export default theme;