import React, { useState, useEffect, useContext, createContext } from 'react';
import io from 'socket.io-client';
import Portal from '@material-ui/core/Portal';
import { auth } from '../utils/firebase';
import { useAuth } from './AuthContext';
import AlertBar from '../components/AlertBar';
import axios2 from '../utils/axios2.js';
import { firebaseErrorMessage } from '../utils/functions';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [conversation, setConversation] = useState({});
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { currentUser, setTotalUnread, setNewLikes } = useAuth();

    useEffect(() => {
        let newSocket;
        if (currentUser) {
            const socketConnect = async () => {
                const idToken = await auth.currentUser.getIdToken();
                newSocket = io('https://adhamo-messages-server.herokuapp.com', { query: { id: currentUser.id }, auth: { token: idToken }});
                setSocket(newSocket);
            }
            socketConnect();
        }
        return () => {
            if (newSocket) {
                newSocket.close();
            }
        };
    }, [currentUser?.id]);

    useEffect(() => {
        if (currentUser) {
            setMessage('');
            setError('');
            const fetchConversations = async () => {
                try {
                    const idToken = await auth.currentUser.getIdToken();
                    const response = await axios2.get(`/api/users/conversations/${currentUser.id}`, { headers: { Authorization: idToken } });
                    setConversations(response.data.conversations);
                    setTotalUnread(response.data.conversations.reduce((acc, conversation) => (acc + (conversation.unread ? conversation.unread : 0)), 0));
                    setNewLikes(response.data.newLikes);
                } catch (err) {
                    console.log(err);
                    if (err.response) {
                        if (err.response.data.message === "Pensez à envoyer des messages aux personnes qui vous intéressent, pour faire des rencontres.") {
                            setMessage("Pensez à envoyer des messages aux personnes qui vous intéressent, pour faire des rencontres.");
                        } else {
                            setError(err.response.data.message);
                        }                        
                    } else {
                        setError(firebaseErrorMessage(err));
                    }
                }
            };
            fetchConversations();
        }
    }, [currentUser?.id, setTotalUnread, setNewLikes]);

    useEffect(() => {
        if (!socket) return;
        socket.on('connect_error', err => console.log(err.message));
        socket.on('message', ({ message, profile }) => {
            setConversations(prevConversations => {
                if (prevConversations.some(conversation => conversation.profile.id === message.sender)) {
                    return prevConversations.map(conversation => {
                        if (conversation.profile.id === message.sender) {
                            return { ...conversation, messages: [...conversation.messages, message], unread: conversation.selected ? 0 : (conversation.unread ? conversation.unread + 1 : 1)};
                        } else {
                            return conversation;
                        }
                    })
                } else {
                    const newConversation = { profile, messages: [message], selected: false, unread: 1 };
                    return [...prevConversations, newConversation];
                }
            });
        });
        socket.on('newLike', () => {
            setNewLikes(prevNewLikes => prevNewLikes + 1);
        });
        socket.on('blocked', data => {
            setError(data.error);
        });
        return () => {
            socket.off('connect_error');
            socket.off('message');
            socket.off('newLike');
            socket.off('error');
        };
    }, [socket, setNewLikes]);

    useEffect(() => {
        if (conversations.length) {
            setTotalUnread(conversations.reduce((acc, conversation) => (acc + (conversation.unread ? conversation.unread : 0)), 0));
        }
    }, [conversations, setTotalUnread]);

    return (
        <SocketContext.Provider value={{ socket, conversations, setConversations, conversation, setConversation }}>
            <Portal>
                <AlertBar message={message} error={error} />
            </Portal>
            {children}
        </SocketContext.Provider>
    );
};