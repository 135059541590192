import React from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import CopyrightIcon from '@material-ui/icons/Copyright';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import appStoreBadge from '../img/Download_on_the_App_Store_Badge_FR_RGB_blk_100517.svg';
import googlePlayBadge from '../img/google-play-badge.png';

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.secondary.light,
        padding: '0.5rem 0',
        color: '#555'
    },
    link: {
        textDecoration: 'none'
    },
    badges: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            cursor: 'pointer'
        }
    },
    copyright: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '1.5rem 0'
    },
    iconCopyright: {
        marginRight: '0.5rem'
    }
}));

const Footer = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Container maxWidth='lg'>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} container direction='column' justify='center' alignItems='center'>
                        <Typography variant='h5'>Suivez-nous</Typography>
                        <div>
                            <IconButton>
                                <a href='https://www.facebook.com/Adhamo.conseils' target='_blank' rel="noopener noreferrer"><FacebookIcon fontSize="large" color="secondary" /></a>
                            </IconButton>
                            <IconButton>
                                <a href='https://twitter.com/Adhamo15' target='_blank' rel="noopener noreferrer"><TwitterIcon fontSize="large" color="secondary" /></a>
                            </IconButton>
                            <IconButton>
                                <a href='https://www.instagram.com/adhamo_conseils/' target='_blank' rel="noopener noreferrer"><InstagramIcon fontSize="large" color="secondary" /></a>
                            </IconButton>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <List>
                            <ListItem button dense divider>
                                <Link to='/about' className={classes.link}><ListItemText secondary='A Propos' /></Link>
                            </ListItem>
                            <ListItem button dense divider>
                                <Link to='/cgu' className={classes.link}><ListItemText secondary='Conditions Générales' /></Link>
                            </ListItem>
                            <ListItem button dense divider>
                                <Link to='/confidentialite' className={classes.link}><ListItemText secondary='Politique de Confidentialité' /></Link>
                            </ListItem>
                            <ListItem button dense divider>
                                <Link to='/cookies' className={classes.link}><ListItemText secondary='Politique relative aux Cookies' /></Link>
                            </ListItem>
                            <ListItem button dense>
                                <Link to='/aide' className={classes.link}><ListItemText secondary='Nous contacter' /></Link>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={4} container direction='column' justify='center' alignItems='center'>
                        <Typography variant='h5'>Bientôt disponible</Typography>
                        <div className={classes.badges}>
                            <img src={appStoreBadge} width='140' alt='app store' />
                            <img src={googlePlayBadge} width='170' alt='google play' />
                        </div>                        
                    </Grid>
                </Grid>
                <Typography className={classes.copyright} variant='body1' color='secondary'><CopyrightIcon className={classes.iconCopyright} /> Adhamo - 2022</Typography>
            </Container>
        </div>
    );
};

export default Footer;