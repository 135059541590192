import React from 'react';
import Header from '../components/Home/Header';
import CardsHome from '../components/Home/CardsHome';
import CookiesBar from '../components/Home/CookiesBar';
import CallToDonate from '../components/Home/CallToDonate';
import Stages from '../components/Home/Stages';
import BlogIntro from '../components/Home/BlogIntro';
import HelmetMetaData from '../utils/HelmetMetaData';

const Home = () => {
    return (
        <>
            <HelmetMetaData 
                title='Site de rencontre sérieux et 100% gratuit' 
                description="Découvrez une opportunité fascinante de faire des rencontres sincères et authentiques gratuitement sur Adhamo"
                quote='Site de rencontre sérieux et 100% gratuit'
                image="https://i.ibb.co/BN2PXxJ/couple.jpg"
                url='https://www.adhamo.com'
            />
            <Header />
            <Stages />
            <BlogIntro />
            <CallToDonate />
            <CardsHome />
            <CookiesBar />
        </>
    );
};

export default Home;