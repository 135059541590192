import { useCallback, useState }  from 'react';
import { auth } from '../utils/firebase';
import axios2 from '../utils/axios2';

const useOnlineProfiles = () => {
    const [onlineProfiles, setOnlineProfiles] = useState([]);
    const [errorOnline, setErrorOnline] = useState('');

    const getProfiles = useCallback(async () => {
        setErrorOnline('');
        try {
            const idToken = await auth.currentUser?.getIdToken();
            const profiles = await axios2.get('/api/users/getusers', { headers: { Authorization: idToken } });
            setOnlineProfiles(profiles.data);
        } catch (err) {
            console.log(err);
            setErrorOnline(err.response?.data.message);
        }
    }, []);

    return { onlineProfiles, getProfiles, errorOnline };
};

export default useOnlineProfiles;