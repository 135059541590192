import mockup1 from '../img/mockup1.jpg';
import mockup2 from '../img/mockup2.jpg';
import mockup3 from '../img/mockup3.jpg';

export default [
    {
        img: mockup1,
        imgTitle: 'Pc Portable',
        title: 'Au travail',
        description: 'Connectez-vous dès que vous y pensez. De nouveaux profils vous attendent chaque jour pour découvrir de nouvelles personnalités.'
    },
    {
        img: mockup2,
        imgTitle: 'Smartphone',
        title: 'En déplacement',
        description: 'Votre application de dating partout avec vous grâce à Adhamo. Ne manquez aucune occasion de faire une rencontre surprenante.'
    },
    {
        img: mockup3,
        imgTitle: 'Tablette',
        title: 'A la maison',
        description: 'Bien installé au fond de votre canapé avec Adhamo, sélectionnez les profils, faites connaissance, surprenez et soyez surpris. '
    }
]