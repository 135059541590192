export const getAge = dateOfBirth => {
    const today = new Date();
    const birth = new Date(dateOfBirth);
    let age = today.getFullYear() - birth.getFullYear();
    const months = today.getMonth() - birth.getMonth();
    if (months < 0 || (months === 0 && today.getDate() < birth.getDate())) {
        age--;
    }
    return age;
};

export const getDate = (ageLow, ageHigh) => {
    const today = new Date().getTime();
    const DateLow = new Date(today - ageLow * 1000 * 3600 * 24 * 365);
    const DateHigh = new Date(today - ageHigh * 1000 * 3600 * 24 * 365);
    return [DateLow, DateHigh];
};

export const firebaseErrorMessage = err => {
    switch(err.code) {
        case 'auth/wrong-password':
            return "Mot de passe invalide ou vous ne disposez pas d'un mot de passe.";
        case 'auth/user-not-found':
            return "Aucun utilisateur correspondant à ces identifiants.";
        case 'auth/invalid-email':
            return "Adresse email non valide.";
        case 'auth/email-already-in-use':
            return "Cet email est déjà utilisé, merci de vous connecter.";
        case 'auth/operation-not-allowed':
            return "Opération non autorisée.";
        case 'auth/weak-password':
            return "Mot de passe trop faible: 8 caractères minimum.";
        case 'auth/expired-action-code':
            return "Action expirée, merci de réessayer.";
        case 'auth/invalid-credential':
            return "Identifiants non valides, merci de vous reconnecter et de réessayer.";
        case 'auth/requires-recent-login':
            return "Merci de vous reconnecter pour pouvoir accomplir cette opération.";
        case 'auth/user-mismatch':
            return "Les identifiants ne correspondent pas à cet utilisateur."
        default: 
            return err.message;
    }
};

export const iframeLoad = () => {
    // let iframeRight = document.createElement('iframe');
    // iframeRight.src = 'https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=14&l=ur1&category=prime_video&banner=0TN5PWYJ2PS86QH0BG82&f=ifr&linkID=75385685d5650e1835a5c95e49caa89d&t=votsan-21&tracking_id=votsan-21';
    // iframeRight.width = '160';
    // iframeRight.height = '600';
    // iframeRight.scrolling = 'no';
    // iframeRight.border = '0';
    // iframeRight.marginWidth = '0';
    // iframeRight.frameBorder = '0';
    // let iframeLeft = document.createElement('iframe');
    // iframeLeft.src = 'https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=14&l=ur1&category=amu&banner=0RMC3YSX314NTNY83202&f=ifr&linkID=96bd361947acd095ca174a627d24fde8&t=votsan-21&tracking_id=votsan-21';
    // iframeLeft.width = '160';
    // iframeLeft.height = '600';
    // iframeLeft.scrolling = 'no';
    // iframeLeft.border = '0';
    // iframeLeft.marginWidth = '0';
    // iframeLeft.frameBorder = '0';
    // document.getElementById('ad2').appendChild(iframeRight);
    // document.getElementById('ad1').appendChild(iframeLeft);
};