import React from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import { useAuth } from '../../context/AuthContext';
import stage1 from '../../img/stage1.jpg';
import stage2 from '../../img/stage2.jpg';
import stage3 from '../../img/stage3.jpg';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '3.8rem 0',
        fontWeight: 500
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '0 0.5rem'
        }
    },
    title: {
        textAlign: 'center',
        color: '#444',
        fontWeight: 500,
        marginBottom: '2.5rem',
        fontSize: '1.4rem'
    },
    badge: {
        padding: '1rem',
        height: 'auto',
        borderRadius: '100%',
        lineHeight: '0.5',
        fontSize: '0.85rem'
    },
    images: {
        maxWidth: 325,
        borderRadius: '100%',
        objectFit: 'contain'
    },
    subtitle: {
        textAlign: 'center',
        marginTop: '0.9rem',
        fontSize: '1.1rem',
        fontWeight: 500,
        color: '#444'
    }, 
    btn: {
        marginTop: '2.5rem'
    }
}));

const Stages = () => {
    const classes = useStyles();
    const { currentUser } = useAuth();

    return (
        <section className={classes.root}>
            <Container maxWidth='lg' className={classes.container}>
                <Typography variant='h6' className={classes.title}>Pour rejoindre la communauté Adhamo et commencer à faire des rencontres, rien de plus facile:</Typography>
                <Grid container justify='center' alignItems='center' spacing={3}>
                    <Grid item xs={12} md={4} container justify='center' alignItems='center' direction='column'>
                        <Badge classes={{badge: classes.badge}} badgeContent={1} overlap="circle" color="secondary">
                            <img className={classes.images} src={stage1} alt='woman with iphone12' />
                        </Badge>
                        <Typography className={classes.subtitle}>Je crée un compte et complète mon profil en quelques minutes.</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} container justify='center' alignItems='center' direction='column'>
                        <Badge classes={{badge: classes.badge}} badgeContent={2} overlap="circle" color="secondary">
                            <img className={classes.images} src={stage2} alt='woman with iphone12' />
                        </Badge>
                        <Typography className={classes.subtitle}>Je parcours les profils sélectionnés des membres d'Adhamo.</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} container justify='center' alignItems='center' direction='column'>
                        <Badge classes={{badge: classes.badge}} badgeContent={3} overlap="circle" color="secondary">
                            <img className={classes.images} src={stage3} alt='woman with iphone12' />
                        </Badge>
                        <Typography className={classes.subtitle}>Je tchatte ou je like les personnes dont les profils m'intéressent.</Typography>
                    </Grid>
                </Grid>
                <Button className={classes.btn} component={Link} to={currentUser ? '/rencontres' : '/signup'} variant='contained' color='secondary' size='large'>Je fais des rencontres</Button>
            </Container>
        </section>
    );
};

export default Stages;