import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ShareIcon from '@material-ui/icons/Share';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import cardContent from '../../utils/cardContent';
import AdsenseHome from '../Ads/AdsenseHome';

const facebookShareUrl = `https://facebook.com/sharer/sharer.php?u=${encodeURI('https://www.adhamo.com')}`;
const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURI('https://www.adhamo.com')}`;

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#eee',
        padding: '3.8rem 0'
    },
    ad: {
        marginBottom: '2.5rem',
        width: '100%'
    },
    cardsTitle: {
        color: "#444",
        textAlign: 'center',
        marginBottom: '1.8rem',
        fontWeight: 500
    },
    root: {
        maxWidth: 500,
        margin: '0 auto'
    },
    media: {
        height: 250,
    },
    description: {
        color: theme.palette.primary.dark
    },
    tooltip: {
        backgroundColor: theme.palette.secondary.light,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        borderRadius: '10px',
        padding: '0.1rem'
    },
    arrow: {
        color: theme.palette.secondary.light
    },
    button: {
        borderRadius: '13px'
    }
  }));

const CardsHome = () => {
    const classes = useStyles();
    const { currentUser } = useAuth();

    return (
        <div className={classes.container}>
            <Container maxWidth='lg'>
                <div className={classes.ad}>
                    <AdsenseHome />
                </div>
                <Typography variant='h5' className={classes.cardsTitle}>
                    Des opportunités fascinantes à découvrir sur Adhamo en vous connectant de n'importe où !
                </Typography>
                <Grid container spacing={2}>
                    {cardContent.map(mockup => (
                        <Grid item key={mockup.title} xs={12} md={4}>
                            <Card raised className={classes.root}>
                                <CardActionArea>
                                    <CardMedia
                                        className={classes.media}
                                        image={mockup.img}
                                        title={mockup.imgTitle}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5">
                                            {mockup.title}
                                        </Typography>
                                        <Typography className={classes.description} variant="body2">
                                            {mockup.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <IconButton color="secondary" aria-label="partager">
                                        <Tooltip classes={{tooltipArrow: classes.tooltip, arrow: classes.arrow}} arrow interactive title={
                                            <>
                                                <IconButton href={facebookShareUrl} target='_blank'><FacebookIcon color='secondary' /></IconButton>
                                                <IconButton href={twitterUrl} target='_blank'><TwitterIcon color='secondary' /></IconButton>
                                            </>
                                        }>
                                            <ShareIcon />
                                        </Tooltip>
                                    </IconButton>
                                    <Button className={classes.button} variant='outlined' size="small" color="secondary" 
                                        component={Link} to={currentUser ? '/profile' : '/signup'}
                                    >
                                        ADHAMO
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}                
                </Grid>
            </Container>
        </div>
    );
};

export default CardsHome;