import React, { useEffect } from 'react';

const AdsenseHome = ({ currentPath }) => {
    
    useEffect(() => {
        window.adsbygoogle = window.adsbygoogle || []
        window.adsbygoogle.push({})
    }, [currentPath]);

    return (
            <ins 
                className="adsbygoogle"
                key={currentPath}
                style={{display:'block'}}
                data-ad-client="ca-pub-6881284677253875"
                data-ad-slot="8918404409"
                data-ad-format="auto"
                data-full-width-responsive="true"
            />
    );
};

export default AdsenseHome;