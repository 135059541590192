import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetMetaData = ({ quote, title, description, image, url, twitterImg }) => {
    return (
        <Helmet>
            <meta property="type" content="website" />
            <meta property="url" content={url} />
            <meta property="title" content={`Adhamo - ${title}`} />
            <meta property="quote" content={quote} />
            <meta name="description" content={description} />
            <meta property="image" content={image} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`Adhamo - ${title}`} />
            <meta property="og:quote" content={quote} />
            <meta property="og:image" content={image} />
            <meta property='fb:app_id' content='3022247734715781' />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={url} />
            <meta property="og:site_name" content="Adhamo - Site de rencontre sérieux et 100% gratuit" />
            <meta property="og:description" content={description} />
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:title' content={`Adhamo - ${title}`} />
            <meta name='twitter:description' content={description} />
            <meta name='twitter:image' content={twitterImg} />
        </Helmet>
    );
};

export default HelmetMetaData;