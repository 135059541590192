import React, { lazy, Suspense } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import Home from './pages/Home';
import theme from './utils/theme';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer';
import PrivateRoute from './utils/PrivateRoute';
import { ProfilesProvider } from './context/ProfilesContext';
import { SocketProvider } from './context/SocketContext';

const Signup = lazy(() => import('./pages/Signup'));
const Profile = lazy(() => import('./pages/Profile'));
const ProfileEdit = lazy(() => import('./pages/ProfileEdit'));
const Account = lazy(() => import('./pages/Account'));
const Rencontres = lazy(() => import('./pages/Rencontres'));
const Likes = lazy(() => import('./pages/Likes'));
const MesLikes = lazy(() => import('./pages/MesLikes'));
const Messages = lazy(() => import('./pages/Messages'));
const VisitedProfile = lazy(() => import('./pages/VisitedProfile'));
const Cgu = lazy(() => import('./pages/Cgu'));
const CookiesPolicy = lazy(() => import('./pages/CookiesPolicy'));
const ConfidentialityPolicy = lazy(() => import('./pages/ConfidentialityPolicy'));
const Aide = lazy(() => import('./pages/Aide'));
const About = lazy(() => import('./pages/About'));
const Idees = lazy(() => import('./pages/Idees'));
const EmailConfirm = lazy(() => import('./pages/EmailConfirm'));
const Donate = lazy(() => import('./pages/Donate'));
const RencontresPandemie = lazy(() => import('./pages/articles/RencontresPandemie'));
const IdeesRdvVirtuels = lazy(() => import('./pages/articles/IdeesRdvVirtuels'));
const AborderSurInternet = lazy(() => import('./pages/articles/AborderSurInternet'));
const ProfilParfait = lazy(() => import('./pages/articles/ProfilParfait'));
const FaireNePasFaire = lazy(() => import('./pages/articles/FaireNePasFaire'));
const ReagirRefus = lazy(() => import('./pages/articles/ReagirRefus'));
const ChoisirPhoto = lazy(() => import('./pages/articles/ChoisirPhoto'));
const ConseilsSeduireHomme = lazy(() => import('./pages/articles/ConseilsSeduireHomme'));
const ConseilsSeduireFemme = lazy(() => import('./pages/articles/ConseilsSeduireFemme'));
const ConseilsConfiance = lazy(() => import('./pages/articles/ConseilsConfiance'));
const ExercicesConfiance = lazy(() => import('./pages/articles/ExercicesConfiance'));
const ReussirPremierRdv = lazy(() => import('./pages/articles/ReussirPremierRdv'));
const RaisonsIgnore = lazy(() => import('./pages/articles/RaisonsIgnore'));
const FrequenteAutresPersonnes = lazy(() => import('./pages/articles/FrequenteAutresPersonnes'));
const OuvertMalgreDeception = lazy(() => import('./pages/articles/OuvertMalgreDeception'));
const RencontresApresDivorce = lazy(() => import('./pages/articles/RencontresApresDivorce'));
const EtPuisunJour = lazy(() => import('./pages/articles/EtPuisunJour'));
const RaisonsNousTombonsAmoureux = lazy(() => import('./pages/articles/RaisonsNousTombonsAmoureux'));
const BienCommencerRelation = lazy(() => import('./pages/articles/BienCommencerRelation'));
const PourquoiPeurEngager = lazy(() => import('./pages/articles/PourquoiPeurEngager'));
const ExercicesConfidence = lazy(() => import('./pages/articles/ExercicesConfidence'));
const LeaProfil = lazy(() => import('./pages/articles/LeaProfil'));

const useStyles = makeStyles({
    circularProgress: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 65px - 200px)',
        paddingTop: '15vh'
    }
});

const App = () => {
    const { loading } = useAuth();
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Navbar />
            {loading ? (
                <div className={classes.circularProgress}>
                    <CircularProgress color="secondary" size={60} thickness={3} />
                </div> 
            ) : (
                <Suspense fallback={
                    <div className={classes.circularProgress}>
                        <CircularProgress color="secondary" size={60} thickness={3} />
                    </div>
                }>
                    <Switch>
                        <Route path='/' exact>                            
                            <Home />
                        </Route>
                        <Route path='/signup' exact>
                            <Signup />
                        </Route>
                        <Route path='/cgu' exact>
                            <Cgu />
                        </Route>
                        <Route path='/confidentialite' exact>
                            <ConfidentialityPolicy />
                        </Route>
                        <Route path='/cookies' exact>
                            <CookiesPolicy />
                        </Route>
                        <Route path='/idees' exact>
                            <Idees />
                        </Route>
                        <Route path='/about' exact>
                            <About />
                        </Route>
                        <Route path='/rencontres-pandemie' exact>
                            <RencontresPandemie />
                        </Route>
                        <Route path='/idees-rdv-virtuels' exact>
                            <IdeesRdvVirtuels />
                        </Route>
                        <Route path='/aborder-sur-internet' exact>
                            <AborderSurInternet />
                        </Route>
                        <Route path='/rediger-profil-parfait' exact>
                            <ProfilParfait />
                        </Route>
                        <Route path='/faire-ne-pas-faire-ligne' exact>
                            <FaireNePasFaire />
                        </Route>
                        <Route path='/reagir-face-refus' exact>
                            <ReagirRefus />
                        </Route>
                        <Route path='/reussir-photo-profil' exact>
                            <ChoisirPhoto />
                        </Route>
                        <Route path='/conseils-seduire-homme' exact>
                            <ConseilsSeduireHomme />
                        </Route>
                        <Route path='/conseils-seduire-femme' exact>
                            <ConseilsSeduireFemme />
                        </Route>
                        <Route path='/conseils-confiance' exact>
                            <ConseilsConfiance />
                        </Route>
                        <Route path='/exercices-confiance' exact>
                            <ExercicesConfiance />
                        </Route>
                        <Route path='/reussir-premier-rdv' exact>
                            <ReussirPremierRdv />
                        </Route>
                        <Route path='/raisons-ignore' exact>
                            <RaisonsIgnore />
                        </Route>
                        <Route path='/frequente-autres-personnes' exact>
                            <FrequenteAutresPersonnes />
                        </Route>
                        <Route path='/ouvert-malgre-deception' exact>
                            <OuvertMalgreDeception />
                        </Route>
                        <Route path='/rencontres-apres-divorce' exact>
                            <RencontresApresDivorce />
                        </Route>
                        <Route path='/et-puis-un-jour' exact>
                            <EtPuisunJour />
                        </Route>
                        <Route path='/raisons-pour-lesquelles-nous-tombons-amoureux' exact>
                            <RaisonsNousTombonsAmoureux />
                        </Route>
                        <Route path='/conseils-pour-bien-commencer-une-relation-amoureuse' exact>
                            <BienCommencerRelation />
                        </Route>
                        <Route path='/pourquoi-j-ai-peur-de-m-engager' exact>
                            <PourquoiPeurEngager />
                        </Route>
                        <Route path='/16-exercices-to-boost-your-confidence' exact>
                            <ExercicesConfidence />
                        </Route>
                        <Route path='/profil-de-lea' exact>
                            <LeaProfil />
                        </Route>
                        <Route path='/validation-email' exact>
                            <EmailConfirm />
                        </Route>
                        <Route path='/aide' exact>
                            <Aide />
                        </Route>
                        <SocketProvider>
                            <PrivateRoute path='/account' exact>
                                <Account />
                            </PrivateRoute>
                            <ProfilesProvider>
                                <PrivateRoute path='/profile' exact>
                                    <Profile />
                                </PrivateRoute>
                                <PrivateRoute path='/rencontres' exact>
                                    <Rencontres />
                                </PrivateRoute>
                                <PrivateRoute path='/likes' exact>
                                    <Likes />
                                </PrivateRoute>
                                <PrivateRoute path='/meslikes' exact>
                                    <MesLikes />
                                </PrivateRoute>
                                <PrivateRoute path='/messages' exact>
                                    <Messages />
                                </PrivateRoute>
                                <PrivateRoute path='/rencontres/profil' exact>
                                    <VisitedProfile />
                                </PrivateRoute>                    
                                <PrivateRoute path='/profile-edit' exact>
                                    <ProfileEdit />
                                </PrivateRoute>
                                <PrivateRoute path='/don' exact>
                                    <Donate />
                                </PrivateRoute>
                            </ProfilesProvider>
                        </SocketProvider>                    
                        <Redirect to='/' />
                    </Switch>
                </Suspense>
            )}
            <Footer />
        </ThemeProvider>        
    );
}

export default App;
