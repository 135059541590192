import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import creditCards from '../../img/creditCards.png';
import iphone12Max from '../../img/Adhamo-iphone12Max.png'

const useStyles = makeStyles(theme => ({
    root: {
        padding: '3.8rem 0',
        [theme.breakpoints.down('md')]: {
            padding: '2rem 1rem'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '2rem 0'
        }
    },
    text: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 1rem',
        [theme.breakpoints.down('md')]: {
            marginBottom: '1rem'
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    }, 
    title: {
        fontWeight: 700,
        color: '#444',
        marginBottom: '1.4rem'
    },
    subtitle: {
        marginBottom: '0.8rem',
        color: '#444'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem 2rem',
        [theme.breakpoints.down('xs')]: {
            padding: '1rem 0'
        }
    },
    creditCard: {
        marginRight: '2.5rem',
    },
    cards: {
        boxShadow: '3px 3px 3px rgba(0,0,0,0.5)',
        height: 45
    },
    thanks: {
        fontWeight: 500,
        marginTop: '0.5rem',
        color: '#444'
    },
    imgGrid: {
        display: 'grid',
        placeItems: 'center'
    },
    img: {
        objectFit: 'contain', 
        width: '100%'
    }
}));

const CallToDonate = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth='lg'>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={6} className={classes.text}>
                        <Typography variant='h6' className={classes.title}>Soutenez votre application de dating totalement gratuite !</Typography>
                        <Typography className={classes.subtitle}>Nous sommes une petite équipe de passionnés qui se sont lancés dans ce projet afin de vous offrir <strong>une solution gratuite pour faire des rencontres.</strong></Typography>
                        <Typography className={classes.subtitle}>
                            Pour nous soutenir, merci de placer Adhamo dans <strong>la liste blanche de votre bloqueur de publicité. </strong>
                            N'hésitez pas à nous rejoindre <strong>sur les réseaux sociaux</strong> et à partager votre expérience d'Adhamo avec vos amis pour mieux nous faire connaître.
                            Vous pouvez aussi contribuer directement <strong>par un don par carte bancaire ou paypal</strong> en cliquant sur un des 2 liens ci-dessous:
                        </Typography>
                        <div className={classes.buttons}>
                            <a href='https://payrequest.me/adhamo' className={classes.creditCard} target="_blank" rel="noopener noreferrer"><img src={creditCards} alt="cartes de crédit" className={classes.cards} /></a>
                            <form action="https://www.paypal.com/donate" method="post" target="_blank">
                                <input type="hidden" name="hosted_button_id" value="T383LC66K2TDC" />
                                <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - La solution la plus sûre pour payer en ligne!" alt="Donate with PayPal button" />
                                <img alt="" border="0" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" width="1" height="1" />
                            </form>
                        </div>
                        <Typography className={classes.thanks}>
                            Tous vos soutiens sont très appréciés et nous font chaud au coeur. Ils nous motivent chaque jour pour améliorer votre expérience sur Adhamo et ajouter des fonctionalités! 
                            Merci de participer à cette aventure avec nous!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.imgGrid}>
                        <img src={iphone12Max} alt='iphone 12 max' className={classes.img} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default CallToDonate;